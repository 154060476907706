.page {
  overflow: auto;

  .dashboardContent {
    margin-top: 2vh;

    .dashboardDevice {
      font-size: 2.5em;
      font-weight: bold;
      margin-bottom: 2vh;
      margin-left: 2vw;
    }

    .dashboardList {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .iconTitle {
        cursor: initial;
      }
    }
  }
}
