.siteTree {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.siteTree .siteNode {
  cursor: pointer;
}

/*
  * Override react-sortable-tree default styles
*/
.siteTree .rst__placeholder::before {
  border: none;
}

.siteTree .rst__rowContents {
  background-color: rgba(114, 132, 144, 0.2);
  border: none;
  box-shadow: none;
  min-width: auto;
  border-radius: 0;
  padding: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.siteTree .rst__rowTitle > span {
  font-weight: normal;
  font-size: 0.9rem;
}

.siteTree .rst__rowWrapper {
  padding: 0;
}

.siteTree .rst__rowContents .rst__rowLabel {
  padding-right: 10px;
}

.siteTree .iconTitle .rst__rowContents {
  height: 100%;
}

.siteTree .rst__collapseButton {
  width: 14px;
  height: 14px;
}

.siteTree .rst__lineHalfHorizontalRight::before,
.siteTree .rst__lineFullVertical::after,
.siteTree .rst__lineHalfVerticalTop::after,
.siteTree .rst__lineHalfVerticalBottom::after,
.siteTree .rst__lineChildren::after {
  background-color: #504f4f;
}

.siteTree .rst__moveHandle {
  background-color: rgb(114, 132, 144);
  border: none;
  box-shadow: none;
  width: 30px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-size: 37px;
}

.siteTree .rst__rowLandingPad::before,
.siteTree .rst__rowCancelPad::before {
  top: 7px;
  bottom: 7px;
  border-radius: 5px;
}

.siteTree.locked .rst__rowContents {
  background-color: inherit;
  padding-left: 0;
  border-radius: none;
}

.siteTree.locked .rst__moveHandle {
  display: none;
  border-radius: none;
}
