.daterangepicker {
  color: #000;
  text-transform: capitalize;
}

/* add realtime status for mobile */
.realtime-running::before {
  content: '⏸';
  margin-right: 10px;
}

.realtime-paused::before {
  content: '▶';
  margin-right: 10px;
}

@media only screen and (min-device-width: 240px) and (max-device-width: 768px) {
  .daterangepicker.show-ranges.opensleft {
    height: fit-content;
    width: fit-content;
    overflow: scroll;
  }

  .daterangepicker.show-ranges.opensleft.show-calendar {
    width: 100%;
    height: auto;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
  }

  .drp-calendar.left,
  .drp-calendar.right {
    max-width: 100%;
    clear: none !important;
  }

  .ranges {
    text-align: center !important;
  }

  .drp-buttons {
    display: flex;
  }

  .drp-selected {
    word-break: break-all;
  }
}

@media only screen and (min-device-width: 240px) and (max-device-width: 768px) and (orientation: landscape) {
  .daterangepicker.show-ranges.opensleft.show-calendar {
    overflow: scroll;
  }

  .daterangepicker.show-calendar .drp-buttons {
    display: flex;
  }

  .ranges {
    text-align: center;
  }

  .drp-calender.left,
  .drp-calender.right {
    clear: none;
  }

  .drp-buttons {
    justify-content: space-evenly;
  }

  .drp-selected {
    text-align: justify;
    align-self: center;
  }
}
